.app {
  min-height: calc((var(--vh, 1vh) * 100));
}

.content {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: calc((var(--vh, 1vh) * 100));
  color: var(--primary);
}

.page-layout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 72px;
  min-height: calc((var(--vh, 1vh) * 50));
}

.app-header {
  text-align: left;
  padding: 24px 16px;
}

.app-logo {
  height: 34px;
}

.app-body {
  font-family: 'Roboto';
  flex-direction: column;
  text-align: left;
  flex: 1;
  display: flex;
  color: black;
  padding: 0 40px;
}

.app-body h2 em {
  font-style: normal;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.app-link {
  color: #61dafb;
}

li.prediction.active {
  font-weight: bold;
  text-decoration: underline;
}

li.prediction:hover {
  font-weight: bold;
  text-decoration: underline;
}

.is-mobile .contents {
  width: 100%;
  margin: 0;
}

.is-mobile .app-body {
  padding: 0 16px;
}

.is-mobile .app-logo {
  height: 38px;
  margin: 10px 0;
}

.app-body-title h1 {
  font-style: normal;
  font-weight: 700;
}

.app-body-title p {
  font-weight: 600;
  font-size: 24px;
}

.location-search .input-label {
  color: var(--primary);
}

.location-search .input-container {
  display: flex;
}

.map-marker-container * {
  pointer-events: all;
}

.charger-label {
  line-height: 1.25;
  transform: translateY(29px);
  text-shadow: 1px 1px 0 var(--primary), -1px -1px 0 var(--primary),
    1px -1px 0 var(--primary), -1px 1px 0 var(--primary);
}

.charger-number-label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 50%;
  min-width: 16px;
  overflow: hidden;
  line-height: 1;
  background: var(--primary);
  transform: translate(-2px, -6px);
}

.charger-number-label--green {
  background-color: var(--tertiary);
  padding: 3px 4px;
  border-radius: 24px;
}

.small-map-marker-property {
  position: relative;
  width: 40px;
  height: 40px;
  background-image: url('../public/assets/icons/map/place-big.svg');
  transform: translate(-50%, -50%);
  z-index: 3;
  cursor: default;
}

.small-map-marker-charger {
  position: relative;
  width: 30px;
  height: 40px;
  background: center / 100% 100% url('../public/assets/icons/map/charger.svg');
  transform: translate(-50%, -100%);
  transition: var(--transition);
  z-index: 1;
}

.selected .small-map-marker-charger {
  z-index: 3;
  transform: translate(-50%, -100%) translateZ(0) scale(1.2);
}

.selected .small-map-marker-charger.hidden {
  z-index: 4;
}

.map-marker-property {
  position: relative;
  width: 30px;
  height: 40px;
  background-image: url('../public/assets/icons/map/place.svg');
  transform: translate(-50%, -100%);
  z-index: 1;
}

.map-marker-property.hidden,
.small-map-marker-charger.hidden {
  background-image: none;
  z-index: 2;
}

.selected .map-marker-property {
  background-image: url('../public/assets/icons/map/place-selected.svg');
  z-index: 3;
}

.map-marker-property__chargers,
.map-marker-charger-label {
  position: absolute;
  left: -9px;
  top: -6px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1rem;
  font-size: 0.625rem;
  padding: 3px;
  border-radius: 25px;
  background-color: var(--tertiary);
  color: var(--primary);
  font-family: 'ClashDisplay-SemiBold';
  line-height: 1;
}

.map-marker-price {
  position: relative;
  padding: 8px;
  background-color: white;
  border-radius: 24px;
  font-size: 0.75rem;
  line-height: 1;
  font-family: 'ClashDisplay-SemiBold';
  transition: var(--transition);
  transform: translate(-50%, -50%) translateZ(0);
  z-index: 1;
}

.selected .map-marker-price {
  z-index: 3;
  transform: translate(-50%, -50%) translateZ(0) scale(1.2);
}

.map-marker-price.hidden {
  background-color: transparent;
  color: transparent;
  box-shadow: none;
  z-index: 2;
}

.selected .map-marker-price.hidden {
  z-index: 4;
}

.map-marker-price__chargers {
  position: absolute;
  right: -12px;
  top: -13px;
  display: flex;
  align-items: center;
  font-size: 0.625rem;
  padding: 4px;
  border-radius: 25px;
  background-color: var(--tertiary);
  color: var(--primary);
}

.map-marker-price__chargers::before {
  content: url('./images/icons/bolt-small.svg');
  display: block;
  width: 10px;
  height: 10px;
}

.map-marker-charger {
  position: relative;
  background-image: url('../public/assets/icons/map/charger-selected.svg');
  width: 30px;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: translate(-50%, -100%) translateZ(0);
  transition: var(--transition);
}

.map-marker-charger-label {
  background-color: white;
}

.selected .map-marker-charger {
  z-index: 5;
  transform: translate(-50%, -100%) translateZ(0) scale(1.2);
}

.marker-pane {
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25));
}

.range-label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  border-radius: 24px;
  overflow: hidden;
  line-height: 15px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(4px);
}

.anchor {
  position: absolute;
  margin-top: -40px;
  width: 0;
  height: 0;
  visibility: hidden;
}

.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  background: white;
  color: #4a3f2f;
  margin-bottom: 24px;
}

.input-wrapper {
  position: relative;
}

.input-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 8px;
}

.input-header .input-label {
  margin-bottom: 0;
}

.input-header .link {
  padding: 4px 0;
}

.input-field {
  border: 1px solid var(--primary60);
  width: 100%;
  padding: 11px 17px;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 1.5;
  color: #4a3f2f;
  border-radius: 0;
  border-radius: 24px;
}

.input-field::-webkit-outer-spin-button,
.input-field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-field[type='number'] {
  -moz-appearance: textfield;
}

.error .input-field {
  border-color: var(--red);
  border-width: 2px;
  padding: 9px 17px;
}

.input-field::placeholder {
  color: var(--primary70);
}

.input-field--select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.input-container--select .input-container__icon {
  position: absolute;
  top: 50%;
  right: 16px;
  color: var(--primary);
  transform: translateY(-50%);
}

.input-label {
  font-family: 'ClashDisplay-SemiBold';
  line-height: 1.5;
  margin-bottom: 4px;
}

.input-info {
  margin-top: 4px;
}

.input-container--range {
  padding-bottom: 40px;
}

.input-tooltip {
  position: absolute;
  bottom: 0;
  background-color: var(--primary90);
  transform: translateX(-50%);
  padding: 4px 12px;
  border-radius: 2px;
}

.input-tooltip::after {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  top: 0;
  left: 50%;
  background-color: var(--primary90);
  transform: translate(-50%, -50%) rotate(45deg);
}

.input-btn {
  position: absolute;
  top: 14px;
  right: 15px;
}

.input-message {
  margin-top: 8px;
}

.input-error {
  margin-top: 8px;
  font-weight: 700;
  color: var(--red);
}

.radio-container {
  display: flex;
}

.radio-label {
  flex: 1 1 auto;
  cursor: pointer;
}

.radio-label span {
  display: block;
  width: 100%;
  height: 100%;
  padding: 14px;
  border: 1px solid var(--primary60);
  text-align: center;
}

.radio-label.selected span {
  background-color: var(--secondary90);
}

.radio-label:first-of-type span {
  border-radius: 32px 0 0 32px;
  border-width: 1px 0 1px 1px;
}

.radio-label:last-of-type span {
  border-radius: 0 32px 32px 0;
}

.textarea {
  width: 100%;
  padding: 17px;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 1.5;
  color: #4a3f2f;
  border-radius: 24px;
  margin-bottom: 10px;
}

.phone-input-container {
  display: flex;
  align-items: center;
  border: 1px solid #eee;
  padding: 12px 17px;
}

.phone-input-field {
  border: none;
  outline: none;
  width: 100%;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 1.5;
  color: #4a3f2f;
}

.phone-input-prepend {
  font-weight: bold;
  white-space: nowrap;
}

.error-item {
  display: flex;
  gap: 8px;
  align-items: center;
  background-color: var(--red);
  padding: 11px 12px;
  border-radius: 8px;
  color: #ffffff;
  font-weight: 500;
  font-family: 'Roboto';
  font-size: 0.875rem;
  line-height: 1.5;
  transition: all 1s ease-in-out;
}

.radio input,
.checkbox input,
.toggle input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.radio input:focus-visible ~ .radio__label,
.toggle input:focus-visible ~ .toggle__label,
.toggle input:focus-visible ~ .caption,
.toggle--reverse input:focus-visible ~ span:not(.toggle__checkmark),
.checkbox input:focus-visible ~ .checkbox__label,
.radio-label input:focus-visible ~ span {
  outline: 2px solid var(--primary);
  outline-offset: 1px;
}

.admin-map__filters .toggle input:focus-visible ~ span {
  outline: 2px solid white;
}

.checkbox__label a {
  text-decoration: underline;
}

.checkbox__label a:hover {
  color: var(--secondary90);
}

.radio {
  display: flex;
  position: relative;
  cursor: pointer;
  padding: 10px 0 10px 28px;
}

.radio__checkmark::before {
  content: '';
  position: absolute;
  top: 12px;
  left: 0;
  width: 20px;
  height: 20px;
  border: 2px solid var(--primary70);
  border-radius: 50%;
  background: white;
}

.radio__checkmark::after {
  content: '';
  position: absolute;
  top: 16px;
  left: 4px;
  width: 12px;
  height: 12px;
  background-color: var(--secondary90);
  border-radius: 50%;
  transform: scale(0);
}

.radio input:checked ~ .radio__checkmark::before {
  border-color: var(--secondary90);
}

.radio input:checked ~ .radio__checkmark::after {
  transform: scale(1);
}

.checkbox {
  display: flex;
  position: relative;
  cursor: pointer;
  padding: 4px 0 4px 40px;
}

.checkbox__checkmark::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  border: 1px solid var(--primary60);
  border-radius: 8px;
  background: white;
}

.checkbox__checkmark::after {
  content: '';
  position: absolute;
  top: 6px;
  left: 6px;
  width: 20px;
  height: 20px;
  background: var(--primary);
  mask-image: url('./common/Icon/icons/checkmark.svg');
  mask-size: contain;
  transform: scale(0);
}

.checkbox input:checked ~ .checkbox__checkmark::before {
  background: var(--secondary90);
  border-color: var(--secondary90);
}

.checkbox input:checked ~ .checkbox__checkmark::after {
  transform: scale(1);
}

.input-container.error .checkbox__checkmark::before {
  border-color: var(--red);
}

.toggle {
  position: relative;
  display: inline-flex;
  align-self: flex-start;
  padding: 8px 76px 8px 0;
  cursor: pointer;
}

.toggle__label {
  letter-spacing: 0.015em;
  font-family: 'ClashDisplay-SemiBold';
}

.toggle__checkmark::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 64px;
  height: 32px;
  border-radius: 40px;
  background: var(--primary70);
}

.toggle__checkmark::after {
  content: '';
  position: absolute;
  top: 4px;
  right: 36px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  transition: 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.toggle input:checked ~ .toggle__checkmark::before {
  background: var(--tertiary);
}

.toggle input:checked ~ .toggle__checkmark::after {
  transform: translateX(32px);
}

.toggle--reverse {
  padding: 4px 0 4px 76px;
}

.toggle--reverse .toggle__checkmark::before {
  left: 0;
  right: auto;
}

.toggle--reverse .toggle__checkmark::after {
  left: 4px;
  right: auto;
}

.content-container {
  background-color: white;
  border: 1px solid var(--primary60);
  border-radius: 32px;
  width: 100%;
  margin: 0 auto;
  padding: 24px;
}

.is-mobile .content-container {
  padding: 24px 16px;
}

/* Signup */

.signup {
  margin: 80px 0;
}

.signup__header {
  margin-bottom: 40px;
}

.signup__desc {
  margin-top: 16px;
}

.signup__btn {
  width: 100%;
}

.signup__terms {
  margin-bottom: 24px;
}

.signup__terms a {
  text-decoration: underline;
  font-weight: 700;
}

.signup__message {
  display: flex;
  gap: 8px;
  align-items: center;
  max-width: 752px;
  margin: 0 auto 24px;
  padding: 11px 12px;
  border-radius: 8px;
  background-color: var(--tertiary);
  font-weight: 700;
}

.signup__footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin: 0 -24px -24px;
  padding: 24px;
  border-top: 1px solid var(--primary60);
}

.signup__footer .link svg {
  transform: scaleX(-1);
}

.signup__travellers-form {
  display: flex;
  align-items: center;
  gap: 8px;
}

.signup__travellers-form .btn {
  gap: 12px;
}

.signup__travellers-form .input-container {
  flex: 0 0 178px;
}

.signup__travellers-form .input-container:first-of-type {
  flex: 1 1 auto;
}

.signup__edit-header {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.signup__edit-header .btn {
  margin-left: auto;
  gap: 12px;
  padding: 11px 15px;
}

.signup__edit-header .btn svg {
  transform: rotate(45deg);
}

.signup__edit-form {
  display: flex;
  flex-direction: column;
  gap: 40px;
  background-color: var(--primary50);
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 24px;
}

.signup__edit-form .input-container {
  background-color: transparent;
}

.signup__edit-form .singup__checkbox span {
  background-color: white;
}

.signup__edit-form .input-container {
  margin: 0;
}

.signup__later {
  margin: 24px auto 0;
}

.signup__footer .link,
.signup__later {
  padding: 18px 32px;
}

.signup__back {
  display: inline-flex;
  margin-bottom: 24px;
}

.is-mobile .signup {
  margin: 40px 0;
}

.is-mobile .signup__footer {
  margin: 0 -16px -24px;
  padding: 16px;
}

.is-mobile .signup__footer .link {
  padding: 18px 8px;
}

/* From blog client */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: calc((var(--vh, 1vh) * 100));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Page loader */

.page-loader-exit {
  opacity: 1;
}
.page-loader-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}
